* {
  font-family: 'Lighters';
  font-weight: 500;
}
hr {
  border-top: 1px solid black;
  border-bottom: 0px solid red;
}

textarea {
  text-align: justify !important;
}

.tablaGen{
  display: table;
}

.fila{
  display: table-row;
}
.col{
  display: table-cell;
  padding: 12px;
  background: #ddd;
}


.hiddenTable{
  background-color: red;
}