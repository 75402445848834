.MasPageIcon {
  width: 48px !important;
  height: 48px !important;
  z-index: 1 !important;
}
.FavoritosIcon {
  width: 75px !important;
  height: 75px !important;
  z-index: 1 !important;
}
.BackIcon {
  width: 50px !important;
  height: 50px !important;
  z-index: 1 !important;
}

.IconSVG:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.IconSVG:hover {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}
