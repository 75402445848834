.IconSVG_MasPageIcon__xyw6a {
  width: 48px !important;
  height: 48px !important;
  z-index: 1 !important;
}
.IconSVG_FavoritosIcon__2L82H {
  width: 75px !important;
  height: 75px !important;
  z-index: 1 !important;
}
.IconSVG_BackIcon__C2-TW {
  width: 50px !important;
  height: 50px !important;
  z-index: 1 !important;
}

.IconSVG_IconSVG__1WyX8:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.IconSVG_IconSVG__1WyX8:hover {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

* {
  font-family: 'Lighters';
}
hr {
  border-top: 1px solid black;
  border-bottom: 0px solid red;
}
textarea {
  text-align: justify !important;
}

.style_tablaGen__2uu3-{
  display: table;
}

.style_fila__3vp3N{
  display: table-row;
}
.style_col__3YQxP{
  display: table-cell;
  padding: 12px;
  background: #ddd;
}


* {
  font-family: 'Lighters';
}
hr {
  border-top: 1px solid black;
  border-bottom: 0px solid red;
}

textarea {
  text-align: justify !important;
}

.style_tablaGen__3fvow{
  display: table;
}

.style_fila__2Biaq{
  display: table-row;
}
.style_col__1HzJk{
  display: table-cell;
  padding: 12px;
  background: #ddd;
}


.style_hiddenTable__178Ra{
  background-color: red;
}
* {
  font-family: 'Lighters';
  font-weight: 500;
}
hr {
  border-top: 1px solid black;
  border-bottom: 0px solid red;
}

textarea {
  text-align: justify !important;
}

.style_tablaGen__zTwjv{
  display: table;
}

.style_fila__3B4Dc{
  display: table-row;
}
.style_col__3faAw{
  display: table-cell;
  padding: 12px;
  background: #ddd;
}


.style_hiddenTable__2XBrE{
  background-color: red;
}
@font-face {
  font-family: 'Lighters';
  src: local('Lighters'), url(/static/media/Lighters-Regular.b4c71d43.ttf) format('TTF');
}

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('http://fonts.cdnfonts.com/css/nexa-bold')
}

@font-face {
    font-family: 'Lighters';
    font-weight: 600;
    src: local('Lighters'), url(/static/media/Lighters-Light.226c6574.ttf) format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 400;
  src: local('Lighters'), url(/static/media/Lighters-Light.226c6574.ttf) format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 200;
  src: local('Lighters'), url(/static/media/Lighters-ExtraLight.cc197177.ttf) format('TTF');
}

* {
  font-family: 'Lighters' !important;
  text-transform: uppercase;
}

@font-face {
  font-family: "Looney";
  src: local("Looney"),
   url(/static/media/looney.1ec636f4.ttf) format("TTF");
  font-weight: bold;
  }

  th {
    font-weight: 900 !important;
  }

  span {
    font-weight: 900 !important;
  }

