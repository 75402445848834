@font-face {
  font-family: 'Lighters';
  src: local('Lighters'), url('./assets/fonts/Lighters-Regular.ttf') format('TTF');
}

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url('http://fonts.cdnfonts.com/css/nexa-bold')
}

@font-face {
    font-family: 'Lighters';
    font-weight: 600;
    src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 400;
  src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 200;
  src: local('Lighters'), url('./assets/fonts/Lighters-ExtraLight.ttf') format('TTF');
}

* {
  font-family: 'Lighters' !important;
  text-transform: uppercase;
}

@font-face {
  font-family: "Looney";
  src: local("Looney"),
   url("./assets/fonts/looney.ttf") format("TTF");
  font-weight: bold;
  }

  th {
    font-weight: 900 !important;
  }

  span {
    font-weight: 900 !important;
  }
